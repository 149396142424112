import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' }
        }
    }

    componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } })
        }
    }

    openNav() {
        // console.log('open')
        this.setState({ navClose: { right: '0px' } })
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } })
    }

    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector("#main-menu").classList.add("hover-unset");
        }
    }


    handleSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if (event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu')
        else {
            document.querySelectorAll('.nav-submenu').forEach(function (value) {
                value.classList.remove('opensubmenu');
            });
            document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu')
        }
    }

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if (event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
        else {
            document.querySelectorAll('.menu-content').forEach(function (value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
        }
    }

    render() {
        const { translate } = this.props;
        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav" >
                        <div className="toggle-nav" onClick={this.openNav.bind(this)} >
                            <i className="fa fa-bars sidebar-bar"></i>
                        </div>
                        <ul className="nav-menu" style={this.state.navClose}>
                            <li className="back-btn" onClick={this.closeNav.bind(this)} >
                                <div className="mobile-back text-right">
                                    <span >Back</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                </div>
                            </li>
                            <li >
                            {/* <i className="fa-solid fa-house"></i> */}
                                <Link to={`${process.env.PUBLIC_URL}/`} onClick={this.closeNav.bind(this)} >{translate('home')}</Link>
                            </li>
                            <li className="mega-menu web-view">
                                <Link to="#" className="dropdown" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('products')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <div className="mega-menu-container" >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('products')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/collar`} onClick={this.closeNav.bind(this)} >{translate('collar')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/leash`} onClick={this.closeNav.bind(this)} >{translate('leash')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/harness`} onClick={this.closeNav.bind(this)} >{translate('harness')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/body-belt`} onClick={this.closeNav.bind(this)} >{translate('body_belt')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/rope`} onClick={this.closeNav.bind(this)} >{translate('rope')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/chain`} onClick={this.closeNav.bind(this)} >{translate('chain')}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('categories')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/shop/regular`} onClick={this.closeNav.bind(this)} >{translate('regular_products')}</Link></li>
                                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/shop/premium`} onClick={this.closeNav.bind(this)} >{translate('premium_products')}</Link></li> */}
                                                            <li><Link to={`${process.env.PUBLIC_URL}/shop/combo`} onClick={this.closeNav.bind(this)} >{translate('combo_products')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/shop/mega-combo`} onClick={this.closeNav.bind(this)} >{translate('mega_combo_products')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/shop/offers`} onClick={this.closeNav.bind(this)} >{translate('offers_product')}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('premium')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content" >
                                                    <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/collar`} onClick={this.closeNav.bind(this)} >{translate('collar')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/leash`} onClick={this.closeNav.bind(this)} >{translate('leash')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/harness`} onClick={this.closeNav.bind(this)} >{translate('harness')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/body-belt`} onClick={this.closeNav.bind(this)} >{translate('body_belt')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/rope`} onClick={this.closeNav.bind(this)} >{translate('rope')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/product/chain`} onClick={this.closeNav.bind(this)} >{translate('chain')}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='mobile-view'>
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('products')}
                                    <span className="sub-arrow"></span>
                                </Link>
                               <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/collar`} onClick={this.closeNav.bind(this)} >{translate('collar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/leash`} onClick={this.closeNav.bind(this)} >{translate('leash')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/harness`} onClick={this.closeNav.bind(this)} >{translate('harness')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/body-belt`} onClick={this.closeNav.bind(this)} >{translate('body_belt')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/rope`} onClick={this.closeNav.bind(this)} >{translate('rope')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/chain`} onClick={this.closeNav.bind(this)} >{translate('chain')}</Link></li>
                                </ul>
                            </li>
                            <li className='mobile-view'>
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('categories')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/shop/regular`} onClick={this.closeNav.bind(this)} >{translate('regular_products')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/shop/premium`} onClick={this.closeNav.bind(this)} >{translate('premium_products')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/shop/combo`} onClick={this.closeNav.bind(this)} >{translate('combo_products')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/shop/mega-combo`} onClick={this.closeNav.bind(this)} >{translate('mega_combo_products')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/shop/offers`} onClick={this.closeNav.bind(this)} >{translate('offers_product')}</Link></li>
                                </ul>
                            </li>
                            <li className='mobile-view'>
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('premium')}
                                    <span className="sub-arrow"></span>
                                </Link>
                               <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/collar`} onClick={this.closeNav.bind(this)} >{translate('collar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/leash`} onClick={this.closeNav.bind(this)} >{translate('leash')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/harness`} onClick={this.closeNav.bind(this)} >{translate('harness')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/body-belt`} onClick={this.closeNav.bind(this)} >{translate('body_belt')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/rope`} onClick={this.closeNav.bind(this)} >{translate('rope')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/product/chain`} onClick={this.closeNav.bind(this)} >{translate('chain')}</Link></li>
                                </ul>
                            </li>

                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>{translate('smart_pet')}<span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/pet-finder`} onClick={this.closeNav.bind(this)} >{translate('pet_finder')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/my-pets`} onClick={this.closeNav.bind(this)} >{translate('my_pets')}</Link></li>
                                </ul>
                            </li>
                            
                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('about')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/about-us`} onClick={this.closeNav.bind(this)} >{translate('about_us')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/contact`} onClick={this.closeNav.bind(this)} >{translate('contact')}</Link></li>
                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/blog/details`} onClick={this.closeNav.bind(this)} >{translate('blog_detail')}</Link></li> */}
                                </ul>
                            </li>
                            <li >
                                <Link to={`${process.env.PUBLIC_URL}/blogs`} onClick={this.closeNav.bind(this)} >{translate('blogs')}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(NavBar);