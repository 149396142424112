import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import Cookies from 'js-cookie';
class TopBar extends Component {

    constructor (props) {
        super (props)
        this.state = {
            account: false,
       }
       if(Cookies.get('id')){
           this.state.account = true;
       }
    }
    logout = () => {
        Cookies.remove('id');
        window.location.href = '/';

    }
    render() {
        const {translate} = this.props;
        return (
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul>
                                    <li>{translate('topbar_title', { theme_name: ' Multikart' })}</li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i>{translate('call_us')}:  9204092049 </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                
                                <li className="mobile-wishlist compare-mobile"><Link to={`${process.env.PUBLIC_URL}/compare`}><i className="fa fa-random" aria-hidden="true"></i>{translate('compare')}</Link></li>
                                <li className="mobile-wishlist"><Link to={`${process.env.PUBLIC_URL}/wishlist`}><i className="fa fa-heart" aria-hidden="true"></i>{translate('wishlist')}</Link></li>
                                <li className="mobile-orders"><Link to={`${process.env.PUBLIC_URL}/orders`}><i className="fa fa-archive" aria-hidden="true"></i>{translate('orders')}</Link></li>
                                
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i> {translate('my_account')}
                                    {this.state.account ?<ul className="onhover-show-div"><li><a href="/dashboard" data-lng="en">Dashboard</a></li><li><a href="/orders" data-lng="en">My Orders</a></li><li><a href={null} onClick={this.logout} data-lng="en">Log out</a></li></ul>:<ul className="onhover-show-div">
                                        
                                    <li>
                                            <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login</Link>
                                        </li>
                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/register`} data-lng="en">Register</Link>
                                        </li>
                                        </ul>}
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBar);