import React, { Component } from 'react';
import axios from 'axios';
import Breadcrumb from "../common/breadcrumb";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SimpleReactValidator from 'simple-react-validator';
import firebaseapp from '../firebase/firebase';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
const auth = getAuth(firebaseapp);
class Register extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            password: '',
            phone: '',
            otp: '',
            cashback: '',
        }
        this.validator = new SimpleReactValidator();
    }
    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);

    }
    componentDidMount() {
        // Check if the component is in edit mode
        const { match } = this.props;
        const { params } = match;
        const { cashback } = params;
        if (cashback != undefined) {
            this.setState({
                cashback: cashback
            });
        }
    }
    postData = async (datas) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/registerUser', datas, {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data.status == true) {
            // toast.success(response.data.message);
            document.getElementById("addressListSpinner").style.display = "none";
            document.getElementById("messageBox").style.display = "block";
            document.getElementById("messageArea").innerHTML = response.data.message;
            document.getElementById("messageArea").className = "alert alert-success";
            // alert(response.data.message);
            if (this.state.cashback == "") {
                setTimeout(function () {
                    window.location.href = "/login";
                }, 1000);
            } else {
                document.getElementById("cashbackMessage").style.display = "block";
                document.getElementById("otp-verify-section").style.display = "none";
                document.getElementById("verify-button").style.display = "none";
            }
        }
        else {
            // toast.error(response.data.message);
            // alert(response.data.message);
            document.getElementById("addressListSpinner").style.display = "none";
            document.getElementById("messageBox").style.display = "block";
            document.getElementById("messageArea").innerHTML = response.data.message;
        }
    }
    createUser = () => {
        // convert this.state to json 
        if (this.validator.allValid()) {
            document.getElementById("addressListSpinner").style.display = "block";
            document.getElementById("messageBox").style.display = "none";
            this.postData(this.state);
        }
        else {
            this.validator.showMessages();
            // render(<div>{this.validator.message('email')}</div>);
            this.forceUpdate();
        }

    }
    callVerify = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            document.getElementById("addressListSpinner").style.display = "block";
            document.getElementById("verify-button").style.display = "none";
            document.getElementById("messageBox").style.display = "none";
            this.verifyUserExists();
        }
        else {
            this.validator.showMessages();
            // render(<div>{this.validator.message('email')}</div>);
            this.forceUpdate();
        }

    }
    verifyUserExists = async () => {
        this.handleClick();
        let data = {
            email: this.state.email,
            phone: this.state.phone
        }
        // call verifyUserExists using get method
        const response = await axios.get(process.env.REACT_APP_API_URL + '/verifyUserExists', {
            params: data,
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (response.data.status == true) {
            // toast.success(response.data.message);
            document.getElementById("addressListSpinner").style.display = "none";
            document.getElementById("verify-button").style.display = "block";
            document.getElementById("messageBox").style.display = "block";
            document.getElementById("messageArea").innerHTML = response.data.message;
            document.getElementById("messageArea").className = "alert alert-danger";
            // alert(response.data.message);
        }
        else {
            // toast.error(response.data.message);
            // alert(response.data.message);
            document.getElementById("addressListSpinner").style.display = "none";
            document.getElementById("messageBox").style.display = "none";
            // this.createUser();
            this.handleClick();
        }
    }

    handleClick() {
        // e.preventDefault();
        //empty window.recaptchaVerifier on form submit
        document.getElementById("otp-sent-alert").style.display = "none";
        document.getElementById("otp-invalid-alert").style.display = "none";
        document.getElementById("otp-verify-section").style.display = "none";
        this.state.otp = '';
        document.getElementById("verify-button").disabled = true;
        this.showTimer();
        this.sendOtp();
        document.getElementById("otp-sent-alert").style.display = "block";
        document.getElementById("otp-verify-section").style.display = "block";
    }
    showTimer = () => {
        var timeleft = 60;
        var downloadTimer = setInterval(function () {
            document.getElementById("verify-button").innerHTML = timeleft + " sec ";
            document.getElementById("verify-button").style.display = "block";
            timeleft -= 1;
            if (timeleft <= 0) {
                clearInterval(downloadTimer);
                document.getElementById("verify-button").innerHTML = "Resend OTP";
                document.getElementById("verify-button").disabled = false;
            }
        }, 1000);
    }
    verifyCaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow OTP to be sent
            },
            'expired-callback': () => {
                // Response expired, reset reCAPTCHA
                window.recaptchaVerifier.reset();
            }
        }, auth);
    }
    sendOtp = () => {
        this.verifyCaptcha();
        const phoneNumber = "+91" + this.state.phone;
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                // ...
            }).catch((error) => {
                // ...
            });
    }

    verifyOTP = (e) => {
        e.preventDefault();
        document.getElementById("verifyOtpButton").style.display = "none";
        let otp = this.state.otp;
        window.confirmationResult.confirm(otp).then((result) => {
            // User signed in successfully.
            document.getElementById("otp-sent-alert").style.display = "none";
            document.getElementById("otp-invalid-alert").style.display = "none";
            document.getElementById("otp-success-alert").style.display = "block";
            this.setState({ isOtpVerified: true });
            this.createUser();
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            document.getElementById("verifyOtpButton").style.display = "block";
            document.getElementById("otp-sent-alert").style.display = "none";
            document.getElementById("otp-success-alert").style.display = "none";
            document.getElementById("otp-invalid-alert").style.display = "block";
            // ...
        });
    }

    getCashbackBanner = (cashback) => {
        let amount = 0;
        if (cashback == "getflatfiftycashback") {
            amount = 50;
            return "<div style='text-align:center;'><span><Strong style='font-size:20px;'>Get Flat ₹" + amount + " Cashback!</Strong></span><br /><span>Create an account and get ₹" + amount + " cashback directly into your account. Thank you!</span></div>";
        } else if (cashback == "getflathundredcashback") {
            amount = 100;
            return "<div style='text-align:center;'><span><Strong style='font-size:20px;'>Get Flat ₹" + amount + " Cashback!</Strong></span><br /><span>Create an account and get ₹" + amount + " cashback directly into your account. Thank you!</span></div>";
        }
        else if (cashback == "getflattwentycashback") {
            amount = 20;
            return "<div style='text-align:center;'><span><Strong style='font-size:20px;'>Get Flat ₹" + amount + " Cashback!</Strong></span><br /><span>Create an account and get ₹" + amount + " cashback directly into your account. Thank you!</span></div>";
        } else if (cashback == "getfiftypercentcashbackuptohundred") {
            amount = 30;
            return "<div style='text-align:center;'><span><Strong style='font-size:20px;'>Get 50% Cashback Upto ₹100 !</Strong></span><br /><span>Create an account and get upto ₹100 cashback directly into your account. Thank you!</span></div>";
        }
        return ""
    }



    render() {
        const display = this.state.cashback != "" ? "flex" : "none";
        const cashbackdisplay = {
            display: display,
            backgroundColor: "#427fc1", color: "white", width: "100%", justifyContent: "center"
        }
        return (
            <div>
                <Breadcrumb title={'create account'} />


                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className='m-3 p-2' style={cashbackdisplay} dangerouslySetInnerHTML={{ __html: this.getCashbackBanner(this.state.cashback) }}></div>
                            <div className="col-lg-12">
                                <h3>create account</h3>

                                <div className="theme-card"  >

                                    <form className="theme-form">
                                        <div className="form-row">
                                            <div id="recaptcha-container"></div>
                                            <div className="col-md-6">
                                                <label htmlFor="email">Name</label>
                                                <input type="text" name="name" className="form-control" id="name"
                                                    placeholder="Name" value={this.state.name} onChange={this.setStateFromInput} required="" />
                                                {this.validator.message('name', this.state.name, 'required')}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Phone Number</label>
                                                <input type="number" name="phone" className="form-control" id="phone"
                                                    placeholder="Phone number" value={this.state.phone} onChange={this.setStateFromInput} required="" />
                                                {this.validator.message('phone', this.state.phone, 'required|phone')}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">email</label>
                                                <input type="text" className="form-control" id="email"
                                                    placeholder="Email" name="email" value={this.state.email} onChange={this.setStateFromInput} required="" />
                                                {this.validator.message('email', this.state.email, 'required|email')}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Password</label>
                                                <input type="password" className="form-control" id="review"
                                                    placeholder="Enter your password" name="password" value={this.state.password} onChange={this.setStateFromInput} required="" />
                                                {this.validator.message('password', this.state.password, 'required')}
                                            </div>
                                            <div id="addressListSpinner" style={{ textAlign: 'center', zIndex: '999', position: "relative", display: "none" }}>
                                                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                            <div className="col-md-12">
                                                <button className="btn btn-solid" id="verify-button" onClick={(e) => this.callVerify(e)}>Register</button>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="alert alert-success" style={{ display: "none" }} id="otp-sent-alert">
                                                    Your otp has been sent to your phone number.
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3" id="otp-verify-section" style={{ display: "none" }}>
                                                <input type="text" className="form-control" id="number" name="otp" value={this.state.otp}
                                                    placeholder="Enter Your OTP" onChange={this.setStateFromInput} required="" />
                                                <button className="btn btn-solid" id="verifyOtpButton" onClick={(e) => this.verifyOTP(e)}>Verify OTP</button>
                                                <div className="alert alert-danger" style={{ display: "none" }} id="otp-invalid-alert">
                                                    <strong>Oops!</strong> OTP is not valid. Retry again.
                                                </div>
                                                <div className="alert alert-success" style={{ display: "none" }} id="otp-success-alert">
                                                    OTP verified successfully.
                                                </div>
                                            </div>
                                            <div id="cashbackMessage" className="m-2" style={{ display: "none" }}>
                                                <div className="">
                                                    <h6 className="title-font"><b>Cashback Redeemed Successfully</b></h6>
                                                    <p>Your cashback will be credited to your account wallet within the next 7 days. Thank you for registering with us.</p>
                                                    <a href="/login" className="btn btn-solid">Log In</a>
                                                </div>
                                            </div>
                                            {/* <div id="registerBox">
                                                <button type="buttton" onClick={(e) => this.createUser(e)} className="btn btn-solid">create Account</button>
                                            </div> */}
                                            <div id="messageBox" className="m-2" style={{ display: "none" }}>
                                                {/* <h3 id="messageArea" className='text-center'></h3> */}
                                                <div className="alert alert-danger" id="messageArea" role="alert">
                                                </div>

                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Register