import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

class FindMyOwner extends Component {
  constructor(props) {
    super(props);

    // Redirect to login if the user is not logged in
    if (Cookies.get('id') === undefined) {
      Cookies.set('redirect', true);
      Cookies.set("path", "view-pet-finder");
      this.props.history.push('/login');
    }

    // Retrieve the tag_id from URL parameters
    const { match } = this.props;
    const { params } = match;
    const { tag_id } = params;

    this.state = {
      tag_id: tag_id,
      tag: {},
    };

    // Fetch tag data when the component is created
    this.getTagDetails(tag_id);
  }

  // Fetch pet tag details based on tag_id
  getTagDetails = async (tag_id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_NEW}/whoami/${tag_id}`, {
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
        }
      });

      if (response.status === 200 && response.data.tags.length > 0) {
        this.setState({
          tag: response.data.tags[0]
        });
      } else {
        console.error('Tag not found');
      }
    } catch (error) {
      console.error('Error fetching tag data:', error);
    }
  };

  calculateAge = (birthDate) => {
    const today = new Date(); // Get today's date
    const birthDateObj = new Date(birthDate); // Convert the birthDate string to a Date object

    let age = today.getFullYear() - birthDateObj.getFullYear(); // Calculate the age by subtracting years

    // Check if the current date has passed the birthday this year
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    const dayDiff = today.getDate() - birthDateObj.getDate();

    // If birthday hasn't occurred yet this year, subtract 1 from the age
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}
  render() {
    const { tag } = this.state;

    return (
      <div>
        <section className="collection section-b-space">
          <div className="container">
            <h3>Pet Finder - View Details</h3>
            <div className="row">
              <div className="col-md-6">
                <h4>Pet Details</h4>
                <p><strong>Pet Name:</strong> {tag.pet_name}</p>
                <p><strong>Pet Type:</strong> {tag.pet_type}</p>
                <p><strong>Gender:</strong> {tag.gender}</p>
                <p><strong>Date of Birth:</strong> {this.calculateAge(tag.dob)}</p>
              </div>

              {tag.alert_on === 1 ?
                <div className="col-md-6">
                  <h4>Owner Details</h4>
                  <p><strong>Owner Name:</strong> {tag.owner_name? tag.owner_name: "*********"}</p>
                  <p><strong>Contact No:</strong> {tag.contact_no? tag.contact_no: "*********"}</p>
                  <p><strong>Whatsapp No:</strong> {tag.whatsapp_no? tag.whatsapp_no: "*********"}</p>
                  <p><strong>Email:</strong> {tag.email? tag.email: "*********"}</p>
                </div>
                : <></>
              }
              </div>

            <button onClick={() => this.props.history.push('/pet-finder')} className="btn btn-outline">Back to Pet Finder</button>
          </div>
        </section>
      </div>
    );
  }
}

export default FindMyOwner;
