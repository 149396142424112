import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import Cookies from 'js-cookie';
import axios from 'axios';

class UpdatePetFinder extends Component {
  constructor(props) {
    super(props);
    if (Cookies.get('id') === undefined) {
      Cookies.set('redirect', true);
      Cookies.set("path", "update-pet-finder");
      this.props.history.push('/login');
    }
    const { match } = this.props;
    const { params } = match;
    const { tag_id } = params;
    const data = {
      "tag_id": tag_id
    };

    this.state = {
      tag: {},
      formData: {
        email: "",
        dob: "",
        pet_name: "",
        pet_type: "",
        gender: "",
        owner_name: "",
        contact_no: "",
        whatsapp_no: "",
        alert_on: false,
        show_contact: false,
        show_whatsapp: false,
        show_email: false,
      },
    };

    this.getTags(data.tag_id);
  }

  // Fetch the tag data
  getTags = async (tag_id) => {
    const response = await axios.get(process.env.REACT_APP_API_URL_NEW + '/tags/' + tag_id, {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      }
    });
    if (response.data != null) {
      if ((response.data.tags).length > 0) {
        this.setState({
          tag: response.data.tags[0],
        }, () => {
          this.setFormData(response.data.tags[0]);
        });
      }
    }
  };

  // Set form data after fetching tag
  setFormData = (tag) => {
    const dob = this.formatDateForInput(tag.dob);
    this.setState({
      formData: {
        email: tag.email || "",
        dob: dob || "",
        pet_name: tag.pet_name || "",
        pet_type: tag.pet_type || "",
        gender: tag.gender || "",
        owner_name: tag.owner_name || "",
        contact_no: tag.contact_no || "",
        whatsapp_no: tag.whatsapp_no || "",
        alert_on: tag.alert_on || false,
        show_contact: tag.show_contact || false,
        show_whatsapp: tag.show_whatsapp || false,
        show_email: tag.show_email || false,
      }
    });
  };

  // Format date for the input field
  formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Handle form input changes
  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: type === "checkbox" ? checked : value,
      }
    }));
  };

  // Handle form submission
  handleSubmit = async (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const updatedFormData = {
      ...formData,
      image: null,
      health: {},
      food: {},
    };

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL_NEW}/update-tag/${this.state.tag.tag_id}`, updatedFormData);

      if (response.status === 200) {
        this.props.history.push('/pet-finder');
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  render() {
    const { formData } = this.state;

    return (
      <div>
        <section className="collection section-b-space">
          <div className="container">
            {/* <h3>Update Pet Finder</h3> */}
            <form onSubmit={this.handleSubmit}>
              <h3>Pet Details</h3>
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="pet_name">Pet Name:</label>
                  <input
                    type="text"
                    id="pet_name"
                    name="pet_name"
                    value={formData.pet_name}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="pet_type">Pet Type:</label>
                  <select
                    id="pet_type"
                    name="pet_type"
                    value={formData.pet_type}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  >
                    {formData.pet_type !== "" ? <option value={formData.pet_type} selected>{formData.pet_type}</option>
                      : <option value="">Select</option>}
                    <option value="dog">Dog</option>
                    <option value="cat">Cat</option>
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="gender">Pet Gender:</label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  >
                    {formData.gender !== "" ? <option value={formData.gender} selected>{formData.gender}</option>
                      : <option value="">Select</option>}
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>

                <div className="form-group col-md-3">
                  <label className="form-label" htmlFor="dob">Pet Date-of-Birth:</label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    value={formData.dob}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
              </div>

              <h3>Owner Details</h3>
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="owner_name">Owner name:</label>
                  <input
                    type="text"
                    id="owner_name"
                    name="owner_name"
                    value={formData.owner_name}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="contact_no">Contact no:</label>
                  <input
                    type="number"
                    id="contact_no"
                    name="contact_no"
                    value={formData.contact_no}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="whatsapp_no">Whatsapp no:</label>
                  <input
                    type="number"
                    id="whatsapp_no"
                    name="whatsapp_no"
                    value={formData.whatsapp_no}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                  />
                </div>
              </div>

              <h5>Security Settings</h5>
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="alert_on">Turn on Lost Alert:</label>
                  <input
                    className="m-2"
                    type="checkbox"
                    id="alert_on"
                    name="alert_on"
                    checked={formData.alert_on}
                    onChange={this.handleChange}
                  />
                  <p>Check if your pet is lost; this will activate the alert system.</p>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="show_contact">Show Contact:</label>
                  <input
                    className="m-2"
                    type="checkbox"
                    id="show_contact"
                    name="show_contact"
                    checked={formData.show_contact}
                    onChange={this.handleChange}
                  />
                  <p>Check if you want to display your contact information when your pet is lost, so people can reach you.</p>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="show_whatsapp">Show Whatsapp:</label>
                  <input
                    className="m-2"
                    type="checkbox"
                    id="show_whatsapp"
                    name="show_whatsapp"
                    checked={formData.show_whatsapp}
                    onChange={this.handleChange}
                  />
                  <p>Check if you want to display your whatsapp information when your pet is lost, so people can reach you.</p>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="show_email">Show Email:</label>
                  <input
                    className="m-2"
                    type="checkbox"
                    id="show_email"
                    name="show_email"
                    checked={formData.show_email}
                    onChange={this.handleChange}
                  />
                  <p>Check if you want to display your email information when your pet is lost, so people can reach you.</p>
                </div>
              </div>

              <button type="submit" className="m-2 btn btn-outline">Submit</button>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default UpdatePetFinder;
